import { TableProduitFactureProps } from "./TableProduitFactureType";
import { BoxHeadProduit, BoxNoProducts, BoxRowProduit, BoxTableProduit, CelBodyProduit, CelHeadProduit, IconDeleteProduit } from "./TableProduitFactureStyle";
import { Produit } from "../../../../pages/dossierClient/DossierClientType";
import { calculPrixUnitaireHT } from "../../../../core/utility/montant/calculPrixUnitaireHT";
import { calculprixTotalHT } from "../../../../core/utility/montant/calculprixTotalHT";
import { calculPrixTotalTTC } from "../../../../core/utility/montant/calculPrixTotalTTC";

const TableProduitFacture = ({ produits, setProduitFacture }: TableProduitFactureProps): JSX.Element | null => {


    const deleteProduit = (product: Produit) => {
        setProduitFacture((curr: Produit[]) => {
            return curr.filter(prod => prod !== product);
        });
    };

    if (produits.length) {
        return (
            <BoxTableProduit>
                <BoxHeadProduit>
                    <CelHeadProduit $linename="action"></CelHeadProduit>
                    <CelHeadProduit $linename="produit">Nom</CelHeadProduit>
                    <CelHeadProduit $linename="quantite">Qté</CelHeadProduit>
                    <CelHeadProduit $linename="prixUnitaireHT">PU HT</CelHeadProduit>
                    <CelHeadProduit $linename="tva">TVA %</CelHeadProduit>
                    <CelHeadProduit $linename="prixTotalHT">Total HT</CelHeadProduit>
                    <CelHeadProduit $linename="prixTotalTTC">Total TTC</CelHeadProduit>
                </BoxHeadProduit>

                {produits.map((product, index) => (
                    <BoxRowProduit key={index}>
                        <CelBodyProduit $linename="action">
                            <IconDeleteProduit onClick={() => deleteProduit(product)} />
                        </CelBodyProduit>
                        <CelBodyProduit $linename="produit">{product.name}</CelBodyProduit>
                        <CelBodyProduit $linename="quantite">{product.quantity}</CelBodyProduit>
                        <CelBodyProduit $linename="prixUnitaireHT">{calculPrixUnitaireHT(product.total_price_gross, product.tax, product.quantity)}</CelBodyProduit>
                        <CelBodyProduit $linename="tva">{product.tax}</CelBodyProduit>
                        <CelBodyProduit $linename="prixTotalHT">{calculprixTotalHT(product.total_price_gross, product.tax)}</CelBodyProduit>
                        <CelBodyProduit $linename="prixTotalTTC">{calculPrixTotalTTC(product.total_price_gross)}</CelBodyProduit>
                    </BoxRowProduit>
                ))}
            </BoxTableProduit>
        );
    };

    return <BoxNoProducts />;
};

export default TableProduitFacture;
