import { useNavigate, useParams } from "react-router-dom";
import BasePage from "../../components/common/basePage/BasePage";
import Header from "../../components/common/header/header/Header";
import { useToasts } from "../../contexts/toastContext/toastContext";
import { useContext, useEffect, useState } from "react";
import LienDocument from "../../components/feature/dossierClient/lienDocument/LienDocument";
import { SinistreRetourApi, SinistreRetourApiSchema } from "./DossierClientType";
import { catchError } from "../../core/request/catchError";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import DossierEstTransmis from "../../components/feature/dossierClient/dossierEstTransmis/DossierEstTransmis";
import ActionDossierClient from "../../components/feature/dossierClient/actionDossierClient/ActionDossierClient";
import { AuthContext, AuthTest } from '../../contexts/authContext/AuthContext';
import DisplayAssure from "../../components/feature/dossierClient/displayAssure/DisplayAssure";
import DisplayAssurance from "../../components/feature/dossierClient/displayAssurance/DisplayAssurance";
import DisplayVhl from "../../components/feature/dossierClient/displayVhl/DisplayVhl";
import DisplaySinistre from "../../components/feature/dossierClient/displaySinistre/DisplaySinistre";
import TraitementDossier from "../../components/feature/dossierClient/TraitementDossier/TraitementDossier";
import { BoxAccordionDossierClient, BoxDossierClient, BoxHeadingDisplay, BoxHeadingDisplayAssureAssurance, LoaderDossierClient, ParagDossierClient, TilteDossierClient } from "./DossierClientStyle";

const DossierClient = (): JSX.Element | null => {
    const navigate = useNavigate();
    const { idSinistre } = useParams();

    const { pushToast } = useToasts();
    const { token } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [sinistre, setSinistre] = useState<SinistreRetourApi>();
    const [loading, setLoading] = useState<boolean>(true);

    const [dossierTransmit, setDossierTransmit] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        idSinistre && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/${idSinistre}`,
            {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            })
            .then((response) => {
                return response.json();
            })
            .then((data: SinistreRetourApi) => {

                catchError(data);

                if (!SinistreRetourApiSchema.safeParse(data).success) {
                    navigate('/dossier/client');
                    return;
                };

                setDossierTransmit(() => data.dossierTransmit);
                setSinistre(data);
            })
            .catch(() => {
                navigate('/dossier/client');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [idSinistre, token, pushToast, navigate]);

    if (loading) {
        return (
            <BasePage>
                <Header />

                <BoxDossierClient>
                    <LoaderDossierClient />
                </BoxDossierClient>

            </BasePage>
        );
    } else if (!loading && sinistre && sinistre.sinistreAssure) {
        return (
            <BasePage>
                <Header />

                <BoxDossierClient>

                    <BoxHeadingDisplay>
                        <BoxHeadingDisplayAssureAssurance>
                            <TilteDossierClient>Dossier de: {sinistre.sinistreAssure.civilite} {sinistre.sinistreAssure.nom.toUpperCase()} {sinistre.sinistreAssure.prenom}</TilteDossierClient>

                            <ParagDossierClient>N° sinistre: {sinistre.numSinistre}</ParagDossierClient>

                            <ParagDossierClient>Créé le: {sinistre.createdAt && format(new Date(sinistre.createdAt), 'dd/MM/yyyy à HH:mm:ss', { locale: fr })}</ParagDossierClient>

                            <DossierEstTransmis isTransmitted={dossierTransmit} />

                        </BoxHeadingDisplayAssureAssurance>

                        <ActionDossierClient sinistre={sinistre} isTransmitted={dossierTransmit} />

                    </BoxHeadingDisplay>

                    <BoxAccordionDossierClient>
                        <DisplayAssure sinistre={sinistre} />

                        <DisplayAssurance sinistre={sinistre} />
                    </BoxAccordionDossierClient>

                    <BoxAccordionDossierClient>
                        <DisplayVhl sinistre={sinistre} />

                        <DisplaySinistre sinistre={sinistre} />
                    </BoxAccordionDossierClient>

                    <BoxAccordionDossierClient>
                        <LienDocument sinistre={sinistre} />

                        <TraitementDossier
                            sinistre={sinistre}
                            setSinistre={setSinistre}
                            isTransmitted={dossierTransmit}
                            setIsTransmitted={setDossierTransmit}
                        />
                    </BoxAccordionDossierClient>
                </BoxDossierClient>
            </BasePage>
        );
    };

    return null;
};

export default DossierClient;