import { useContext, useState } from "react";
import { AuthContext, AuthTest } from "../../../../contexts/authContext/AuthContext";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import { ButtonTransmetreDossierClient, Loader } from "./BtnTransmetreDossierClientStyle";
import { catchError } from "../../../../core/request/catchError";
import { SinistreRetourApi } from "../../../../pages/dossierClient/DossierClientType";
import { BtnTransmetreDossierClientProps, TransmissionRetourApi } from "./BtnTransmetreDossierClientType";

const BtnTransmetreDossierClient = ({ isVisilble, idSinistre, setDossierEstTransmit, setSinistre }: BtnTransmetreDossierClientProps): JSX.Element | null => {
    const { pushToast } = useToasts();
    const { token } = useContext<AuthTest>(AuthContext).isAuthenticated;
    const [loader, setLoader] = useState<boolean>(false);

    const transmitionDuDossier = () => {
        setLoader(() => true);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/envoi/${idSinistre}`,
            {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then((response) => {
                return response.json();
            })
            .then((data: TransmissionRetourApi) => {

                catchError(data);

                pushToast({ content: "Le dossier a été transmis", type: 'success', duration: 5 });

                setSinistre((curr: SinistreRetourApi) => {
                    return { ...curr, ...data };
                });

                setDossierEstTransmit(() => true);
            })
            .catch((erreur: Error) => {
                pushToast({ content: erreur.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setLoader(() => false);
            });
    };

    if (!isVisilble) {
        return (
            <ButtonTransmetreDossierClient type="button" onClick={transmitionDuDossier}>
                {loader ? <Loader /> : "Transmettre le dossier à l'assurance"}
            </ButtonTransmetreDossierClient>
        );
    };

    return null;
};

export default BtnTransmetreDossierClient;
