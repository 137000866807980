import { FormEvent, useContext, useEffect, useState } from "react";
import { catchError } from "../../../../core/request/catchError";
import Accordion from "../../../common/accordion/Accordion";
import Button from "../../../common/button/Button";
import InputCustom from "../../../common/field/inputCustom/InputCustom";
import { DateKbisSchema, DateKbisType } from "../../../../contexts/authContext/AuthContextType";
import { AuthContext, AuthTest } from '../../../../contexts/authContext/AuthContext';
import { BoxBtnAnnulekbis, BoxBtnKbis, BoxDateKbis } from "./ChangeDeKbisStyle";
import { format, parse } from "date-fns";
import { fr } from "date-fns/locale";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import InputFileAccount from "../../../common/field/inputFileAccount/InputFileAccount";

const ChangeDeKbis = () => {
    const { pushToast } = useToasts();
    const { addDateKbis } = useContext<AuthTest>(AuthContext);
    const { token, id, kbisEdition, kbisExpire } = useContext<AuthTest>(AuthContext).isAuthenticated;

    const [dateEditionKbis, setDateEditionKbis] = useState<string | undefined>('');
    const [dateKbisError, setDateKbisError] = useState<string>('');

    const [kbisFile, setKbisFile] = useState<File>();
    const [kbisFileError, setKbisFileError] = useState<string>('');
    const [dateExpireKbis, setDateExpireKbis] = useState<string | undefined>('');

    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (kbisFile) {
            setBtnIsDisabled(() => false);
        } else {
            setBtnIsDisabled(() => true);
            const date = kbisEdition ? parse(kbisEdition, 'dd/MM/yyyy', new Date()) : new Date('now');
            setDateEditionKbis(() => format(date, 'yyyy-MM-dd', { locale: fr }));
        };
    }, [kbisFile, kbisEdition]);

    useEffect(() => {
        if (kbisEdition) {
            const date = parse(kbisEdition, 'dd/MM/yyyy', new Date());
            setDateEditionKbis(() => format(date, 'yyyy-MM-dd', { locale: fr }));
        } else {
            setDateEditionKbis(() => '');
        };
    }, [kbisEdition]);

    useEffect(() => {
        if (kbisExpire) {
            const date = parse(kbisExpire, 'dd/MM/yyyy', new Date());
            setDateExpireKbis(() => format(date, 'yyyy-MM-dd', { locale: fr }));
        } else {
            setDateExpireKbis(() => '');
        };
    }, [kbisExpire]);

    const annule = () => {
        if (kbisEdition) {
            const date = parse(kbisEdition, 'dd/MM/yyyy', new Date());
            setDateEditionKbis(() => format(date, 'yyyy-MM-dd', { locale: fr }));
        } else {
            setDateEditionKbis(() => '');
        };

        if (kbisExpire) {
            const date = parse(kbisExpire, 'dd/MM/yyyy', new Date());
            setDateExpireKbis(() => format(date, 'yyyy-MM-dd', { locale: fr }));
        } else {
            setDateExpireKbis(() => '');
        };

        setKbisFile(() => undefined);
        setKbisFileError(() => '');
        setDateKbisError(() => '');
        setBtnIsDisabled(() => true);
    };

    const submitData = (e: FormEvent) => {
        e.preventDefault();

        setDateKbisError(() => '');
        setKbisFileError(() => '');

        if (!kbisFile || dateEditionKbis === '') {

            dateEditionKbis === '' && setDateKbisError(() => 'La date d\'édition est requise');

            !kbisFile && setKbisFileError(() => 'Le Kbis est requis');

            return;
        };

        setBtnIsDisabled(() => true);
        setSubmitLoader(() => true);

        const formdata = new FormData();
        formdata.append('dateEditionKbis', JSON.stringify({ dateEditionKbis: dateEditionKbis }));
        formdata.append('kbis', kbisFile);

        fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/utilisateur/update/kbis/${id}`,
            {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formdata
            })
            .then((response) => {
                return response.json();
            })
            .then((data: DateKbisType) => {

                catchError(data);

                if (!DateKbisSchema.safeParse(data).success) {
                    pushToast({ content: `Les données reçues ne sont pas conformes aux attentes`, type: 'alert', duration: 5 });
                    return;
                };

                addDateKbis({
                    kbisEdition: data.kbisEdition,
                    kbisAlerte: data.kbisAlerte,
                    kbisExpire: data.kbisExpire
                });

                pushToast({ content: `Le Kbis a été mis à jour`, type: 'success', duration: 5 });
            })
            .catch((error: Error) => {
                pushToast({ content: error.message, type: 'alert', duration: 5 });
            })
            .finally(() => {
                setSubmitLoader(() => false);
                setKbisFile(() => undefined);
            });
    };

    return (
        <Accordion title="Extrait KBIS">
            <form onSubmit={submitData}>

                <BoxDateKbis>
                    <InputCustom
                        width={48.5}
                        label="Date d'édition du Kbis"
                        id="dateEditionKbis"
                        type="date"
                        value={dateEditionKbis}
                        onChange={(e) => setDateEditionKbis(() => {
                            setBtnIsDisabled(() => false);
                            return e.target.value;
                        })}
                        error={dateKbisError}
                        require={true}
                        disabled={submitLoader}
                    />

                    <InputCustom
                        width={48.5}
                        label="Date d'expiration du Kbis"
                        id="dateEditionKbis"
                        type="date"
                        value={dateExpireKbis}
                        onChange={(e) => setDateExpireKbis(e.target.value)}
                        error=""
                        require={false}
                        disabled={true}
                    />
                </BoxDateKbis>

                <InputFileAccount
                    file={kbisFile}
                    setFile={setKbisFile}
                    label="Extrait KBIS"
                    setError={setKbisFileError}
                    error={kbisFileError}
                    nameError="cni"
                />

                <BoxBtnKbis>
                    <BoxBtnAnnulekbis>
                        <Button
                            label="Annuler"
                            type="button"
                            width={90}
                            color="crimson"
                            colorlabel="#EEEEEE"
                            onClick={annule}
                            loader={false}
                            disabled={btnIsDisabled}
                        />

                        <Button
                            label="Modifier"
                            type="submit"
                            width={100}
                            color="#0288d1"
                            colorlabel="#EEEEEE"
                            loader={submitLoader}
                            disabled={btnIsDisabled}
                        />
                    </BoxBtnAnnulekbis>
                </BoxBtnKbis>
            </form>
        </Accordion>
    );
};

export default ChangeDeKbis;