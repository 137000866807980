import { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import SignaturePad from 'react-signature-pad-wrapper';
import { CirconstanceErreur } from '../../shared/class/CirconstanceErreur';
import { Circonstance } from '../../shared/class/Circonstance';
import { circonstanceValidation } from '../../core/validation/circonstanceValidation';
import { BoxCheckBoxCirconstanceSignature, BoxFieldBtnFormCirconstance, BoxFieldCheckBoxAutreFormCirconstance, BoxFieldCheckBoxFormCirconstance, BoxFieldFormCirconstance, CheckboxContainer, CheckboxContainerBottom, CheckboxContainerTop, FormCirconstanceCustom } from './FormCirconstanceStyle';
import TitleSection from '../../components/common/titleSection/TitleSection';
import InputCustom from '../../components/common/field/inputCustom/InputCustom';
import Select from '../../components/common/field/select/Select';
import Checkbox from '../../components/common/field/checkbox/Checkbox';
import SignatureBox from '../../components/common/field/signatureBox/signatureBox';
import Button from '../../components/common/button/Button';
import Header from '../../components/common/header/header/Header';
import BasePage from '../../components/common/basePage/BasePage';
import { SinistreContext } from '../../contexts/sinistreContext/SinistreContext';
import { CirconstanceType, CirconstanceUpdateSchema } from './FormCirconstanceType';
import { useToasts } from '../../contexts/toastContext/toastContext';
import { format } from 'date-fns';
import TextareaCustom from '../../components/common/field/textareaCustom/TextareaCustom';
import CheckboxSignature from '../../components/feature/signatureConvention/checkboxSignature/CheckboxSignature';
import { catchError } from '../../core/request/catchError';
import { AuthContext, AuthTest } from '../../contexts/authContext/AuthContext';

export default function FormCirconstance() {
  const navigate = useNavigate();
  const { idSinistre } = useParams();
  const { pushToast } = useToasts();
  const { token, id } = useContext<AuthTest>(AuthContext).isAuthenticated;
  const { addSigantureDeclaration } = useContext(SinistreContext);
  const refSiganture = useRef<SignaturePad | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [circonstanceSinistreSelect, setCirconstanceSinistreSelect] = useState<string>("Projection de gravillons");
  const [autreCirconstanceSinistre, setAutreCirconstanceSinistre] = useState<string>("");
  const [erreur, setErreur] = useState<CirconstanceErreur>(new CirconstanceErreur());
  const [circonstances, setCirconstances] = useState<Circonstance>(new Circonstance());

  const [precisionPourAssurance, setPrecisionPourAssurance] = useState<string>('');

  useEffect(() => {
    !idSinistre && navigate('/');
  }, [idSinistre, navigate]);

  useEffect(() => {

    idSinistre && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/simple/${idSinistre}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then((response) => {
        return response.json();
      })
      .then((data: CirconstanceType) => {

        catchError(data);

        if (!CirconstanceUpdateSchema.safeParse(data).success) {
          pushToast({ content: "Les données reçues ne sont pas conformes aux attentes", type: 'alert', duration: 5 });
          return;
        };

        setCirconstances((curr: Circonstance) => {
          return {
            ...curr,
            ...data,
            lieuSinistre: data.lieuSinistre ? data.lieuSinistre : '',
            dateSinistre: data.dateSinistre ? format(new Date(data.dateSinistre), 'yyyy-MM-dd') : '',
            detailGlaceLaterale: data.detailGlaceLaterale ? data.detailGlaceLaterale : '',
            detailOptique: data.detailOptique ? data.detailOptique : '',
            lieuSignature: data.lieuSignature ? data.lieuSignature : '',
            dateSignature: data.dateSignature ? format(new Date(data.dateSignature), 'yyyy-MM-dd') : '',
            detailAutre: data.detailAutre ? data.detailAutre : '',
            detailRetroviseur: data.detailRetroviseur ? data.detailRetroviseur : '',
            precisionPourAssurance: data.precisionPourAssurance ? data.precisionPourAssurance : '',
          };
        });

        setPrecisionPourAssurance(() => {
          return data.precisionPourAssurance ? data.precisionPourAssurance : '';
        });

        if (data.circonstance && data.circonstance !== 'Projection de gravillons') {
          setCirconstanceSinistreSelect('Autre');
          setAutreCirconstanceSinistre(data.circonstance);
        };

        if (data.circonstance && data.circonstance !== 'Projection de gravillons') {
          setCirconstanceSinistreSelect('Autre');
          setAutreCirconstanceSinistre(data.circonstance);
        };
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      });
  }, [idSinistre, pushToast, token]);

  const onChangePrincipal = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    switch (name) {
      case 'pareBrise':
      case 'glaceLaterale':
      case 'lunetteArriere':
      case 'optique':
      case 'calibrageCamera':
      case 'retroviseur':
      case 'toitPanoramique':
      case 'reparationImpact':
      case 'luEtApprouveDeclaration':
      case 'autre':
        setCirconstances({ ...circonstances, [name]: checked });
        break;

      default:
        setCirconstances({ ...circonstances, [name]: value });
        break;
    };
  };

  const onChangeCirconstance = (e: ChangeEvent<HTMLSelectElement>) => {
    setCirconstanceSinistreSelect(e.target.value);

    if (e.target.value !== 'Autre') {
      setAutreCirconstanceSinistre("");
    };
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const signaturePad = refSiganture.current;

    const circonstanceDegat = {
      ...circonstances,
      precisionPourAssurance: precisionPourAssurance,
      circonstance: circonstanceSinistreSelect === 'Autre' ? autreCirconstanceSinistre : circonstanceSinistreSelect
    };

    const isValide = circonstanceValidation(circonstanceDegat);
    setErreur({ ...erreur, ...isValide.detail });

    if (isValide.error) {
      return;
    };

    //au moins un champ dois être coché
    if (
      !circonstanceDegat.optique &&
      !circonstanceDegat.lunetteArriere &&
      !circonstanceDegat.glaceLaterale &&
      !circonstanceDegat.pareBrise &&
      !circonstanceDegat.retroviseur &&
      !circonstanceDegat.calibrageCamera &&
      !circonstanceDegat.toitPanoramique &&
      !circonstanceDegat.reparationImpact &&
      !circonstanceDegat.autre &&
      circonstanceDegat.precisionPourAssurance === ''
    ) {
      pushToast({ content: "Au moins un choix de dégat apparent doit être sélectionné", type: 'alert', duration: 5 });
      return;
    };

    if (circonstanceDegat.glaceLaterale === true && circonstanceDegat.detailGlaceLaterale === '') {
      setErreur({ ...erreur, detailOptique: "Vous devez préciser les dégâts sur les glaces latérales" });
      return;
    };

    //si optique est true alors optidetail dois être remplit
    if (circonstanceDegat.optique === true && circonstanceDegat.detailOptique === '') {
      setErreur({ ...erreur, detailOptique: "Vous devez préciser les dégats sur les optiques" });
      return;
    };

    //si retriviseur est a true alors retriviseur détail doit remplis
    if (circonstanceDegat.retroviseur === true && circonstanceDegat.detailRetroviseur === '') {
      setErreur({ ...erreur, detailRetroviseur: "Vous devez préciser les dégats sur les rétroviseurs" });
      return;
    };

    if (circonstanceDegat.luEtApprouveDeclaration === false) {
      pushToast({ content: "La case 'Lu et approuvé' est obligatoire", type: 'alert', duration: 5 });
      return;
    };

    if (signaturePad?.isEmpty()) {
      pushToast({ content: "La signature est obligatoire", type: 'alert', duration: 5 });
      return;
    };

    addSigantureDeclaration(`${signaturePad?.toDataURL()}`);

    if (!idSinistre || !id || !token) {
      pushToast({ content: "Un élément d'identification est manquant", type: 'alert', duration: 5 });
      return;
    };

    setLoading(() => true);

    idSinistre && id && token && fetch(`${process.env.REACT_APP_URL_API_CESSION_CREANCE}/api/centre/sinistre/${idSinistre}`, {
      method: "PUT",
      headers: { 'Authorization': `Bearer ${token}` },
      body: JSON.stringify(circonstanceDegat)
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        catchError(data);

        pushToast({ content: "Les données des circonstances et dégâts sont bien enregistrées", type: 'success', duration: 5 });
        navigate(`/declaration/convention/${idSinistre}`);
      })
      .catch((error: Error) => {
        pushToast({ content: error.message, type: 'alert', duration: 5 });
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  const resetSignature = () => {
    const signaturePad = refSiganture.current;
    if (!signaturePad?.isEmpty()) {
      refSiganture.current?.clear();
    };
  };

  return (
    <BasePage>
      <Header />

      <FormCirconstanceCustom onSubmit={onSubmit}>

        <TitleSection title="Circonstance du sinistre" />

        <BoxFieldFormCirconstance>
          <InputCustom
            width={49}
            label="Lieu du sinistre"
            id="lieuSinistre"
            type="text"
            value={circonstances.lieuSinistre}
            onChange={onChangePrincipal}
            error={erreur.lieuSinistre}
          />

          <InputCustom
            width={49}
            label="Date du sinistre"
            id="dateSinistre"
            type="date"
            value={circonstances.dateSinistre}
            onChange={onChangePrincipal}
            error={erreur.dateSinistre}
          />
        </BoxFieldFormCirconstance>

        <BoxFieldFormCirconstance>
          <Select
            width={49}
            label="Circonstances du sinistre"
            id="circonstance"
            value={circonstanceSinistreSelect}
            onChange={onChangeCirconstance}
            error={""}
          />

          {circonstanceSinistreSelect === 'Autre' &&
            <InputCustom
              width={49}
              label="Si autre à préciser"
              id="autreCirconstanceSinistre"
              type="text"
              value={autreCirconstanceSinistre}
              onChange={(e) => setAutreCirconstanceSinistre(e.target.value)}
              error={erreur.circonstance}
            />}
        </BoxFieldFormCirconstance>

        <TitleSection title="Dégats apparents" />

        <CheckboxContainerTop>
          <CheckboxContainer>
            <Checkbox
              width={100}
              label="Pare-brise"
              id="pareBrise"
              name="pareBrise"
              checked={circonstances.pareBrise}
              onChange={onChangePrincipal}
              reverse={true}
            />

            <Checkbox
              width={100}
              label="Lunette arrière"
              id="lunetteArriere"
              name="lunetteArriere"
              checked={circonstances.lunetteArriere}
              onChange={onChangePrincipal}
              reverse={true}
            />

            <Checkbox
              width={100}
              label="Calibrage caméra"
              id="calibrageCamera"
              name="calibrageCamera"
              checked={circonstances.calibrageCamera}
              onChange={onChangePrincipal}
              reverse={true}
            />
          </CheckboxContainer>

          <CheckboxContainer>
            <Checkbox
              width={100}
              label="Toit panoramique"
              id="toitPanoramique"
              name="toitPanoramique"
              checked={circonstances.toitPanoramique}
              onChange={onChangePrincipal}
              reverse={true}
            />

            <Checkbox
              width={100}
              label="Réparation d'impact"
              id="reparationImpact"
              name="reparationImpact"
              checked={circonstances.reparationImpact}
              onChange={onChangePrincipal}
              reverse={true}
            />
          </CheckboxContainer>
        </CheckboxContainerTop>

        <CheckboxContainerBottom>
          <BoxFieldCheckBoxAutreFormCirconstance>
            <BoxFieldCheckBoxFormCirconstance>
              <Checkbox
                label={!circonstances.optique ? "Optique" : "Optique à préciser"}
                id="optique"
                name="optique"
                checked={circonstances.optique}
                onChange={onChangePrincipal}
                reverse={true}
                mb={5}
              />

              {circonstances.optique &&
                <InputCustom
                  width={100}
                  label=""
                  id="detailOptique"
                  type="text"
                  value={circonstances.detailOptique}
                  onChange={onChangePrincipal}
                  error={erreur.detailOptique}
                  require={true}
                />}
            </BoxFieldCheckBoxFormCirconstance>

            <BoxFieldCheckBoxFormCirconstance>
              <Checkbox
                label={!circonstances.glaceLaterale ? "Glace latérale" : "Glace latérale à préciser"}
                id="glaceLaterale"
                name="glaceLaterale"
                checked={circonstances.glaceLaterale}
                onChange={onChangePrincipal}
                reverse={true}
                mb={5}
              />

              {circonstances.glaceLaterale &&
                <InputCustom
                  width={100}
                  label=""
                  id="detailGlaceLaterale"
                  type="text"
                  value={circonstances.detailGlaceLaterale}
                  onChange={onChangePrincipal}
                  error={erreur.detailGlaceLaterale}
                  require={true}
                />}
            </BoxFieldCheckBoxFormCirconstance>
          </BoxFieldCheckBoxAutreFormCirconstance>

          <BoxFieldCheckBoxAutreFormCirconstance>
            <BoxFieldCheckBoxFormCirconstance>
              <Checkbox
                label={!circonstances.retroviseur ? "Rétroviseur" : "Rétroviseur à préciser"}
                id="retroviseur"
                name="retroviseur"
                checked={circonstances.retroviseur}
                onChange={onChangePrincipal}
                reverse={true}
                mb={5}
              />

              {circonstances.retroviseur &&
                <InputCustom
                  width={100}
                  label=""
                  id="detailRetroviseur"
                  type="text"
                  value={circonstances.detailRetroviseur}
                  onChange={onChangePrincipal}
                  error={erreur.detailRetroviseur}
                  require={true}
                />}
            </BoxFieldCheckBoxFormCirconstance>

            <BoxFieldCheckBoxFormCirconstance>
              <Checkbox
                label={!circonstances.autre ? "Autre" : "Autre à préciser"}
                id="autre"
                name="autre"
                checked={circonstances.autre}
                onChange={onChangePrincipal}
                reverse={true}
                mb={5}
              />

              {circonstances.autre &&
                <InputCustom
                  width={100}
                  label=""
                  id="detailAutre"
                  type="text"
                  value={circonstances.detailAutre}
                  onChange={onChangePrincipal}
                  error={erreur.detailAutre}
                  require={true}
                />}
            </BoxFieldCheckBoxFormCirconstance>
          </BoxFieldCheckBoxAutreFormCirconstance>

        </CheckboxContainerBottom>

        <TextareaCustom
          width={100}
          label="Précision pour la compagnie d'assurance"
          id="precisionPourAssurance"
          value={precisionPourAssurance}
          onChange={(e) => setPrecisionPourAssurance(e.target.value)}
          error={''}
          require={false}
        />

        <BoxFieldFormCirconstance>

          <InputCustom
            width={49}
            label="Fait à"
            id="lieuSignature"
            type="text"
            value={circonstances.lieuSignature}
            onChange={onChangePrincipal}
            error={erreur.lieuSignature}
          />

          <InputCustom
            width={49}
            label="Le"
            id="dateSignature"
            type="date"
            value={circonstances.dateSignature}
            onChange={onChangePrincipal}
            error={erreur.dateSignature}
          />
        </BoxFieldFormCirconstance>

        <TitleSection title="Signature de L'assuré(e)" />

        <BoxCheckBoxCirconstanceSignature>
          <CheckboxSignature
            label="Lu et approuvé"
            id="luEtApprouveDeclaration"
            name="luEtApprouveDeclaration"
            checked={circonstances.luEtApprouveDeclaration}
            onChange={onChangePrincipal}
          />
        </BoxCheckBoxCirconstanceSignature>

        <SignatureBox
          widthContainer={55}
          width={100}
          ref={refSiganture}
          error={""}
          reset={resetSignature}
        />

        <BoxFieldBtnFormCirconstance>
          <Button
            label="Précédent"
            type="button"
            width={115}
            color="#BDBDBD"
            colorlabel="#EEEEEE"
            loader={false}
            onClick={() => { navigate(`/declaration/assurance/${idSinistre}`) }}
          />

          <Button
            label="Suivant"
            type="submit"
            width={115}
            color="#0288d1"
            colorlabel="#EEEEEE"
            loader={loading}
          />
        </BoxFieldBtnFormCirconstance>
      </FormCirconstanceCustom>
    </BasePage>
  );
};