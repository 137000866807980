import { useState } from "react";
import { FormAddProduitProps } from "./FormAddProduitType";
import { BoxFieldAddProduit, BoxFormBodyAddProduit, BoxFormHeadAddProduit, FieldRequired, IconAdd, IconReset, InputAddProcuct, LabelFieldAddProduit, SelectAddProcuct } from "./FormAddProduitStyle";
import { useToasts } from "../../../../contexts/toastContext/toastContext";
import { Produit, ProduitSchema } from "../../../../pages/dossierClient/DossierClientType";
import { produitcalculPrixUnitaireHT } from "../../../../core/utility/montant/produitcalculPrixUnitaireHT";

const tvaValue = [
    { value: "20", label: 20 },
    { value: "10", label: 10 },
    { value: "8.5", label: 8.5 },
    { value: "7.7", label: 7.7 },
    { value: "5", label: 5 },
    { value: "2.1", label: 2.1 },
    { value: "0", label: 0 }
];

const FormAddProduit = ({ setProduitFacture }: FormAddProduitProps): JSX.Element => {

    const { pushToast } = useToasts();

    const [nomProduit, setNomProduit] = useState<string>('');
    const [quantite, setQuantite] = useState<string>('1');
    const [prixUnitaireHT, setPrixUnitaireHT] = useState<string>('');
    const [tva, setTva] = useState<string>('20');

    const handleChangePrixUnitaireHT = (e: any) => {
        setPrixUnitaireHT(e.target.value.replace(",", "."));
    };

    const addProduit = () => {

        //(((prixUnitaireHT * quantite) / 100) * tva) + (prixUnitaireHT * quantite)
        const produit = {
            name: nomProduit,
            tax: tva,
            total_price_gross: produitcalculPrixUnitaireHT(prixUnitaireHT, quantite, tva),
            quantity: quantite
        };

        const produitSchema = ProduitSchema.safeParse(produit);

        if (!produitSchema.success) {
            produitSchema.error.issues.forEach(issue => {
                pushToast({ content: issue.message, type: 'alert', duration: 5 });
            });
            return;
        };

        setProduitFacture((curr: Produit[]) => {
            const newProduit: Produit[] = [
                ...curr, produit
            ];

            return newProduit;
        });

        resetFormAddProduit();
    };

    const resetFormAddProduit = () => {
        setNomProduit('');
        setQuantite('1');
        setPrixUnitaireHT('');
        setTva('20');
    };

    return (
        <div>
            <BoxFormHeadAddProduit>
                <LabelFieldAddProduit $linename="nom">Nom<FieldRequired >*</FieldRequired></LabelFieldAddProduit>

                <LabelFieldAddProduit $linename="prixUnitaireHT">PU HT<FieldRequired >*</FieldRequired></LabelFieldAddProduit>

                <LabelFieldAddProduit $linename="quantite">Qté<FieldRequired >*</FieldRequired></LabelFieldAddProduit>

                <LabelFieldAddProduit $linename="tva">TVA<FieldRequired >*</FieldRequired></LabelFieldAddProduit>

                <LabelFieldAddProduit $linename="actionAjouter"></LabelFieldAddProduit>

                <LabelFieldAddProduit $linename="actionEffacer"></LabelFieldAddProduit>
            </BoxFormHeadAddProduit>

            <BoxFormBodyAddProduit>
                <BoxFieldAddProduit $linename="nom">
                    <InputAddProcuct type="text" value={nomProduit} onChange={(e) => setNomProduit(e.target.value)} />
                </BoxFieldAddProduit>

                <BoxFieldAddProduit $linename="prixUnitaireHT">
                    <InputAddProcuct type="text" pattern="[0-9]+([\.][0-9]{0,2})?" value={prixUnitaireHT} onChange={handleChangePrixUnitaireHT} />
                </BoxFieldAddProduit>

                <BoxFieldAddProduit $linename="quantite">
                    <InputAddProcuct type="number" min={1} value={quantite} onChange={(e) => setQuantite(e.target.value)} />
                </BoxFieldAddProduit>

                <BoxFieldAddProduit $linename="tva">
                    <SelectAddProcuct value={tva} onChange={(e) => setTva(e.target.value)}>
                        {tvaValue.map((opt, index) => (
                            <option key={index} value={opt.value}>{opt.label}</option>
                        ))}
                    </SelectAddProcuct>
                </BoxFieldAddProduit>

                <BoxFieldAddProduit $linename="actionAjouter">
                    <IconAdd onClick={addProduit} />
                </BoxFieldAddProduit>

                <BoxFieldAddProduit $linename="actionEffacer">
                    <IconReset onClick={resetFormAddProduit} />
                </BoxFieldAddProduit>
            </BoxFormBodyAddProduit>
        </div>
    );
};

export default FormAddProduit;